import React, { useEffect, useState } from "react";
import styles from "./SignupPage.module.scss";
import * as cx from "classnames";
import leaves from "../LoginPage/leaves.jpeg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faLock } from "@fortawesome/free-solid-svg-icons";
import { Container, Form, Button, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {doSignUp, saveToDB } from "../../utils/firebase";

export default function SignupPage() {
  const [user, setUser] = useState({
    email: "",
    password: "",
    username: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
  }, []);

  return (
    <div className={styles.loginPage}>
      <div
        className={cx(styles.curvedHeader)}
        style={{ backgroundImage: `url(${leaves})` }}
      ></div>
      <Container>
        <div className={styles.headingText}>
          <h1>Welcome to FarmLu</h1>
          <p>Create your account</p>
        </div>
        <Container>
          <Form autoComplete="off" className={styles.formGroup}>
            <Row className={styles.inputGroup}>
              <Col className={styles.inputIcon} xs={1}>
                <FontAwesomeIcon align="end" icon={faUser} />
              </Col>
              <Col>
                <Form.Control
                  type="text"
                  placeholder="username"
                  value={user.username}
                  onChange={(e) =>
                    setUser({ ...user, username: e.currentTarget.value })
                  }
                />
              </Col>
            </Row>
            <br />
            <Row className={styles.inputGroup}>
              <Col className={styles.inputIcon} xs={1}>
                <FontAwesomeIcon align="end" icon={faUser} />
              </Col>
              <Col>
                <Form.Control
                  type="text"
                  placeholder="email"
                  value={user.email}
                  onChange={(e) =>
                    setUser({ ...user, email: e.currentTarget.value })
                  }
                />
              </Col>
            </Row>
            <br />
            <Row className={styles.inputGroup}>
              <Col className={styles.inputIcon} xs={1}>
                <FontAwesomeIcon align="end" icon={faLock} />
              </Col>
              <Col>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  value={user.password}
                  onChange={(e) =>
                    setUser({ ...user, password: e.currentTarget.value })
                  }
                />
              </Col>
            </Row>
          </Form>
          <br />
          <Row>
            <Col>
              <p onClick={(e) => navigate("/login")}>login</p>
            </Col>
          </Row>
        </Container>
        <br />
        <div className={cx("d-grid gap-2")}>
          <Button
            className={cx(styles.signupButton)}
            onClick={(e) => doSignUp(user)}
          >
            Sign Up
          </Button>
        </div>
      </Container>
    </div>
  );
}
