import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { Form, Col, Row, Container } from 'react-bootstrap';
import styles from './FLSearchInput.module.scss';

export default function FLSearchInput() {
  return (
    <Container>
      <Form className={styles.formGroup}>
        <Row className={styles.inputGroup}>
          <Col className={styles.inputIcon} xs={1}>
            <FontAwesomeIcon align="end" icon={faMagnifyingGlass} />
          </Col>
          <Col>
            <Form.Control type="text" placeholder="Search" />
          </Col>
        </Row>
      </Form>
    </Container>
  )
}
