import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PlantCard from "../../components/FLPlantCard/FLPlantCard";
import contentful from "../../utils/contentful";
import Carousel from "react-bootstrap/Carousel";
import * as cx from 'classnames';
import styles from './PlantPage.module.scss';
import nextId from "react-id-generator";

export default function PlantPage() {
  const [entry, setEntry] = useState(null);
  let { id } = useParams();

  useEffect(() => {
    contentful
      .getEntry(id)
      .then((response) => {
        setEntry(response);
      })
      .catch(console.error);
  }, []);
  return (
    entry && (
      <div>
        <h1>{entry.fields.title}</h1>
        <Carousel>
        {entry.fields.images.map((image) => {
          return (
            <Carousel.Item key={nextId()}>
              <img
                className={cx('d-block', styles.carouselImage)}
                src={image.fields.file.url}
                alt="Third slide"
              />
              <Carousel.Caption>
                <h3>{image.fields.title}</h3>
                <p>
                  Praesent commodo cursus magna, vel scelerisque nisl
                  consectetur.
                </p>
              </Carousel.Caption>
            </Carousel.Item>
          );
        })}
        </Carousel>
      </div>
    )
  );
}
