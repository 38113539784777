import { useContext, useEffect } from "react";
import { Button, Card, Row, Col, Carousel, Container } from "react-bootstrap";
import styles from "./FLPlantCard.module.scss";
import * as cx from "classnames";
import { useNavigate } from "react-router-dom";
import { CartContext } from "../../contexts/CartContext";
import nextId from "react-id-generator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartPlus } from "@fortawesome/free-solid-svg-icons";

export default function PlantCard({ plantCard }) {
  const { cart, setCart } = useContext(CartContext);
  const navigate = useNavigate();
  const { fields, sys } = plantCard;
  return (
    <Card className={cx(styles.card)}>
      <Carousel>
        {fields.images.map((image) => {
          return (
            <Carousel.Item key={nextId()}>
              <img
                className={cx("d-block", styles.cardImage)}
                src={image.fields.file.url}
                alt="Third slide"
              />
            </Carousel.Item>
          );
        })}
      </Carousel>
      {/* <Card.Img variant="top" src={fields.images[0].fields.file.url} /> */}
      <Card.Body className={cx(styles.cardBody)}>
        <Card.Title>
          <h1>{fields.title}</h1>
        </Card.Title>
        <Card.Text>{fields.description}</Card.Text>
        <Container className={cx(styles.cardButtons)}>
          <Row>
            <Col xs={2}>
              <Button
              className={styles.addToCartButton}
                variant="primary"
                onClick={(e) => {
                  setCart([...cart, {...fields}]);
                  const plus1Span = e.currentTarget.getElementsByClassName('plus1')[0];
                  // TODO: this class needs to be removed once animation is done, remove in useeffect
                  plus1Span.classList.add(cx(styles.fadeInAndOutClass));
                  setTimeout(() => {
                    plus1Span.classList.remove(cx(styles.fadeInAndOutClass));
                  }, 500);
                }}
              >
                <FontAwesomeIcon align="end" icon={faCartPlus} />
                <span className={cx("plus1")}>+1</span>
              </Button>
            </Col>
            <Col>
              <Button
                variant="primary"
                onClick={() => navigate(`/plant/details/${sys.id}`)}
              >
                More Details
              </Button>
            </Col>
            <Col className={cx(styles.price)}>
                <h4>N$ {fields.price || 25.99}</h4>
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
}
