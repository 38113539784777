import React, { useState, useEffect } from 'react'
import { Container } from 'react-bootstrap';
import FLStatusCard from '../../components/FLStatusCard/FLStatusCard';
import styles from './TrackingPage.module.scss';
import * as moment from 'moment';

const getDate = (date) => {
    const format1 = "YYYY-MM-DD HH:mm"
    return moment(new Date(date)).format(format1);
}


const itemsArray = [{
    date: getDate('2023-02-06 13:04'),
    numberPlate: '0812345673',
    description: 'Your order has been paid by 0812345673 and recieved',
},{
    date: getDate('2023-02-08 10:00'),
    numberPlate: 'FL 23466 X',
    description: 'The package have been shipped by the supplier and are on a flight to Hosea Kutako',
},{
    date: getDate('2023-02-09 14:44'),
    numberPlate: 'NA 234674 W',
    description: 'The package is being transported from Hosea Kutako to Windhoek',
},{
    date: getDate('2023-02-10 05:30'),
    numberPlate: 'NA 34567 W',
    description: 'The package has been given to a transporter and is on its way from Windhoek to Oshakati',
},{
    date: getDate('2023-02-11 13:04'),
    numberPlate: 'Maria Nakale',
    description: 'The package has been been delivered and was signed off',
}]

export default function TrackingPage() {
    const [index, setIndex] = useState(0)

    useEffect(()=>{
        itemsArray.forEach((item, _index)=>{
            setTimeout(()=>{
            setIndex(_index+1)
            }, 1000 * _index)
        })
    }, [])

  return (
    <Container className={styles.trackingPage}>
        <h1>Order Tracking</h1>
        {itemsArray.slice(0, index).map((item, _index) => {
            return <FLStatusCard {...item} />
        })}
    </Container>
  )
}
