// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { collection, addDoc } from "firebase/firestore";


// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
const firebaseConfig = {
  apiKey: "AIzaSyC5dPkGCajyHBtaExMK_XRNA-07IQf4Z6g",
  authDomain: "farmlu.firebaseapp.com",
  projectId: "farmlu",
  storageBucket: "farmlu.appspot.com",
  messagingSenderId: "393938969790",
  appId: "1:393938969790:web:9a56df087ef6c597e1aaef",
  measurementId: "G-D1DXBW19GW"
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
export const analytics = getAnalytics(firebaseApp);
export const firestore = getFirestore(firebaseApp);

export const doSignUp = (user) => {
    const auth = getAuth();
    createUserWithEmailAndPassword(auth, user.email, user.password)
      .then((userCredential) => {
        // Signed in
        const fireAuthuser = userCredential.user;
        // console.log(fireAuthuser);
        saveToDB(user, fireAuthuser)
        // navigate("/shop/seedlings");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error(error);
      });
  };

  export const saveToDB = async (user, {uid}) => {
    try {
      const docRef = await addDoc(collection(firestore, "users"), {
        username: user.username,
        email: user.email,
        uid: uid,
      });
    //   console.log("Document written with ID: ", docRef);
    } catch (e) {
    //   console.error("Error adding document: ", e);
    }
  };

