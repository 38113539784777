import React from "react";
import styles from "./LoginPage.module.scss";
import * as cx from "classnames";
import leaves from "./leaves.jpeg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faLock } from "@fortawesome/free-solid-svg-icons";
import { Container, Form, Button, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import logo from "../../logo.png";

export default function LoginPage() {
  const navigate = useNavigate();
  return (
    <div className={styles.loginPage}>
      <div
        className={cx(styles.curvedHeader)}
        style={{ backgroundImage: `url(${leaves})` }}
      >
        <h1>Login</h1>
      </div>
      <Container>
          <img style={{ width: "10em" }} src={logo} alt="FarmLu Logo" />
        <Container>
          <Form className={styles.formGroup}>
            <Row className={styles.inputGroup}>
              <Col className={styles.inputIcon} xs={1}>
                <FontAwesomeIcon align="end" icon={faUser} />
              </Col>
              <Col>
                <Form.Control type="text" placeholder="email/username" />
              </Col>
            </Row>
            <br />
            <Row className={styles.inputGroup}>
              <Col className={styles.inputIcon} xs={1}>
                <FontAwesomeIcon align="end" icon={faLock} />
              </Col>
              <Col>
                <Form.Control type="password" placeholder="Password" />
              </Col>
            </Row>
          </Form>
          <br />
          <Row>
            <Col xs={6}>
              <p onClick={(e) => navigate("/signup")}>forgot password</p>
            </Col>
            <Col xs={{ offset: 3 }}>
              <p onClick={(e) => navigate("/signup")}>signup</p>
            </Col>
          </Row>
        </Container>
        <br />
        <div className={cx("d-grid gap-2")}>
          <Button
            className={cx(styles.loginButton)}
            onClick={(e) => navigate("/shop/seedlings")}
          >
            Login
          </Button>
        </div>
      </Container>
    </div>
  );
}
