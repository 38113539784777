import React from "react";
import leaves from "../LoginPage/leaves.jpeg";
import styles from "./OrderStatusPage.module.scss";
import * as cx from "classnames";
import { Container, Button } from "react-bootstrap";
import { useNavigate } from 'react-router-dom'

export default function OrderStatusPage() {
  const navigate = useNavigate();

  return (
    <Container
      fluid
      className={cx(styles.orderStatusPage)}
      style={{ backgroundImage: `url(${leaves})` }}
    >
      <div className={styles.centerDiv}>
        <div className={styles.centerDivContent}>
          <h3>Order Successfully placed</h3>
          <p>
            Your order was placed Successfully please make payment via e-wallet
            or bank transfer and send proof of payment to +26481...., once
            payment has been confirmed, tracking details for your packing will
            be available
          </p>
          <Button onClick={(e)=>navigate('/shop/seedlings')}>Continue Shopping</Button>
          <br />
          <p onClick={(e)=>navigate('/tracking')}>Track your order</p>
        </div>
      </div>
    </Container>
  );
}
