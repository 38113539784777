import React, { useContext, useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import { CartContext } from "../../contexts/CartContext";
import styles from "./CartPage.module.scss";
import { Button, Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import FLCartCard from "../../components/FLCartCard/FLCartCard";
import * as cx from "classnames";
import { useNavigate } from "react-router-dom";

// Ripped this off the internet
// https://stackoverflow.com/questions/24444738/sum-similar-keys-in-an-array-of-objects
// Takes an array of objects and sums the ones that have the same value at a key
// Returns an array of objects but similar objects are sumerized into one, with total price
const sumByKey = (arr, key, value) => {
  const map = new Map();
  for (const obj of arr) {
    // if no object in map pass a default
    const currObj = map.get(obj[key]) || {
      ...obj,
      qty: 0,
      totalPrice: 0,
    };
    //   Update the qty and totalPrice
    map.set(obj[key], {
      ...currObj,
      totalPrice: currObj.totalPrice + obj[value],
      qty: currObj.qty + 1,
    });
  }
  const res = Array.from(map, ([title, newObj]) => ({
    ...newObj,
    qty: newObj.qty,
    totalPrice: newObj.totalPrice,
  }));
  return res;
};

export default function CartPage() {
  const { cart, setCart } = useContext(CartContext);
  const [calculatedItems, setCalculatedItems] = useState([]);
  const deliveryCostPercentage = 150;
  const navigate = useNavigate();

  const totalPrice = sumByKey(cart, "title", "price").reduce(
    (accumulator, obj) => accumulator + obj.totalPrice,
    0
  );

  const findOneAndRemoveArr = (arr, value) => {
    let arrCopy = JSON.parse(JSON.stringify(arr));
    const index = arrCopy.findIndex((item) => item.title === value);
    if (index > -1) {
      arrCopy.splice(index, 1);
      setCart(arrCopy);
      return;
    }
  };

  const to2Decimal = (number) => {
    return Math.round((number + Number.EPSILON) * 100) / 100;
  };

  useEffect(() => {
    setCalculatedItems(sumByKey(cart, "title", "price"));
  }, [cart]);

  return (
    <Container className={cx(styles.cartPage)}>
      <div className={cx(styles.scrollCart)}>
        {calculatedItems.map((item, _idex) => {
          return (
            <FLCartCard
              key={item.title + _idex}
              images={item.images}
              title={item.title}
              description={item.description}
              qty={item.qty}
              price={to2Decimal(item.totalPrice)}
              removeItemHandler={(e) => findOneAndRemoveArr(cart, item.title)}
            />
          );
        })}
      </div>

      <Container className={cx(styles.checkoutComp)}>
        <Row className={cx(styles.preTotal)}>
          <Col xs={6}>Subtotal</Col>
          <Col xs={6}>N$ {to2Decimal(totalPrice)}</Col>
          <Col xs={6}>Shipping Cost</Col>
          <Col xs={6}>N$ {to2Decimal(deliveryCostPercentage)}</Col>
        </Row>
        <Row className={cx(styles.total)}>
          <Col xs={6}>Total</Col>
          <Col xs={6}>N$ {to2Decimal(totalPrice + deliveryCostPercentage)}</Col>
          <Button
            onClick={(e) => navigate("/shop/order/status/:id")}
            className={styles.checkoutButton}
          >
            Place your order
          </Button>
          <br />
          <Button
            onClick={(e) => setCart([])}
            className={styles.emptyCartButton}
          >
            Empty Cart
          </Button>
        </Row>
      </Container>
    </Container>
  );
}
