import { useContext } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { CartContext } from "../../contexts/CartContext";
import styles from './FLNavbar.module.scss';
import * as cx from 'classnames';
import logo from '../../logo.png';

function FLNavbar() {
  const { cart } = useContext(CartContext);

  return (
    <Navbar fixed="top" className={cx(styles.navbar)} bg="light" expand="lg">
      <Container className={cx(styles.navbarContainer)}>
        <Navbar.Brand className={cx(styles.navbarLogo)} href="/">
          <img src={logo} alt="FarmLu" />
        </Navbar.Brand>
            <Nav.Item className={cx(styles.smCart)} style={{cursor: "pointer"}} onClick={(e) => window.location.replace('/cart')}>
              <div className={styles.smCartIcons}>
              <FontAwesomeIcon align="end" icon={faCartShopping} />
              <span>{cart.length}</span>
              </div>
            </Nav.Item>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="#link">The farm</Nav.Link>
            <NavDropdown title="Shop" id="basic-nav-dropdown">
              <NavDropdown.Item href="/shop/seedlings">Seedlings</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/shop/goats">Goats</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="/orders">Orders</Nav.Link>
          </Nav>
          <Nav className="me-auto"></Nav>
          <Nav className="me-auto">
            <Nav.Item className={cx(styles.mdCart)} style={{cursor: "pointer"}} onClick={(e) => window.location.replace('/cart')}>
              <FontAwesomeIcon align="end" icon={faCartShopping} />
              <span>{cart.length}</span>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default FLNavbar;
