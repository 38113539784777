import React from "react";
import styles from "./ResetPassword.module.scss";
import * as cx from "classnames";
import leaves from "../LoginPage/leaves.jpeg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faLock } from "@fortawesome/free-solid-svg-icons";
import { Container, Form, Button, Row, Col } from "react-bootstrap";
import { useNavigate } from 'react-router-dom'

export default function ResetPassword() {
  const navigate = useNavigate();
  return (
    <div className={styles.loginPage}>
      <div
        className={cx(styles.curvedHeader)}
        style={{ backgroundImage: `url(${leaves})` }}
      ></div>
      <Container>
        <div className={styles.headingText}>
          <h1>Forgot your password</h1>
          <p>enter your username or email</p>
        </div>
        <Container>
          <Form className={styles.formGroup}>
            <Row className={styles.inputGroup}>
              <Col className={styles.inputIcon} xs={1}>
                <FontAwesomeIcon align="end" icon={faUser} />
              </Col>
              <Col>
                <Form.Control type="text" placeholder="email/username" />
              </Col>
            </Row>
            <br />
          </Form>
          <br />
      <Row>
        <Col xs={6}><p onClick={(e)=>navigate('/login')}>login</p></Col>
        <Col xs={{offset: 3 }}><p onClick={(e)=>navigate('/signup')}>signup</p></Col>
      </Row>
        </Container>
        <br />
        <div className={cx("d-grid gap-2")}>
          <Button className={styles.resetButton} onClick={(e)=>navigate('/shop/seedlings')}>Reset Password</Button>
        </div>
      </Container>
    </div>
  );
}
