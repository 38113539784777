import { useEffect, useState } from "react";
import "./App.css";
import PlantPage from "./pages/PlantPage/PlantPage";
import SeedlingsShopPage from "./pages/SeedlingsShopPage/SeedlingsShopPage";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import FLNavbar from "./components/FLNavbar/FLNavbar";
import FLFooter from "./components/FLFooter/FLFooter";
import LandingPage from "./pages/LandingPage/LandingPage";
import { CartContext } from "./contexts/CartContext";
import CartPage from "./pages/CartPage/CartPage";
import LoginPage from "./pages/LoginPage/LoginPage";
import SignupPage from "./pages/SignupPage/SignupPage";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import OrderStatusPage from "./pages/OrderStatusPage/OrderStatusPage";
import GoatShopPage from "./pages/GoatShopPage/GoatShopPage";
import TrackingPage from "./pages/TrackingPage/TrackingPage";
import OrdersPage from "./pages/OrdersPage/OrdersPage";

const router = createBrowserRouter([
  {
    path: "/",
    // element: <LandingPage />,
    element: <SeedlingsShopPage />,
    errorElement: <div>Error Page</div>,
  },
  {
    path: "/login",
    element: <LoginPage/>,
    errorElement: <div>Error Page</div>,
  },
  {
    path: "/signup",
    element: <SignupPage/>,
    errorElement: <div>Error Page</div>,
  },
  {
    path: "/shop/seedlings",
    element: (
        <SeedlingsShopPage />
    ),
    errorElement: <div>Error Page</div>,
  },
  {
    path: "/shop/goats",
    element: (
        <GoatShopPage />
    ),
    // errorElement: <div>Error Page</div>,
  },
  {
    path: "/shop/order/status/:id",
    element: (
        <OrderStatusPage />
    ),
    errorElement: <div>Error Page</div>,
  },
  {
    path: "/plant/details/:id",
    element: <PlantPage />,
    errorElement: <div>Error Page</div>,
  },
  {
    path: "/cart",
    element: <CartPage />,
    errorElement: <div>Error Page</div>,
  },
  {
    path: "/orders",
    element: <OrdersPage />,
    errorElement: <div>Error Page</div>,
  },
  {
    path: "/tracking",
    element: <TrackingPage />,
    errorElement: <div>Error Page</div>,
  },
  {
    path: "/resetPassword",
    element: <ResetPassword/>,
    errorElement: <div>Error Page</div>,
  },
]);

function App() {
  const [cart, setCart] = useState(JSON.parse(localStorage.getItem('cart')) || []);
  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart))
  }, [cart])
  
  return (
    <div className="App">
      <CartContext.Provider value={{cart, setCart}}>
        <FLNavbar />
        <div style={{marginTop: '5em'}}>
        <RouterProvider router={router} />
        </div>
      </CartContext.Provider>
      {/* <FLFooter /> */}
    </div>
  );
}

export default App;
