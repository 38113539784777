import { useEffect, useState } from "react";
import PlantCard from "../../components/FLPlantCard/FLPlantCard";
import contentful from "../../utils/contentful";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CartContext } from "../../contexts/CartContext";
import nextId from "react-id-generator";
import FLSearchInput from "../../components/FLSearchInput/FLSearchInput";
import * as cx from 'classnames';
import styles from './GoatShopPage.module.scss';

export default function GoatShopPage() {
  const [entry, setEntries] = useState([]);
  const [goatCards, setGoatCards] = useState([]);

  useEffect(() => {
    contentful
      .getEntry("1bzQSiEe4ijmBafEH7GYxZ")
      .then((response) => {
        setEntries(response.fields);
        setGoatCards(response.fields.goatCards);
      })
      .catch(console.error);
  }, []);
  return (
    <Container className={cx(styles.goatShopPage)}>
      <h1>{entry.title}</h1>
      <FLSearchInput />
      <br />
      <Row>
      {goatCards.map((plantCard) => {
        return (
            <Col xs={12} md={3} lg={3} key={nextId()}>
              <PlantCard plantCard={plantCard} />
            </Col>
        );
    })}
    </Row>
    </Container>
  );
}
