import React from "react";
import { Container, Badge, Row, Col } from "react-bootstrap";
import styles from './FLStatusCard.module.scss';

export default function FLStatusCard(props) {
  return (
    <Container className={styles.statusCardContainer}>
      <Row>
        <Col xs={4}>
          <Badge bg="secondary">{props.date}</Badge>
        </Col>
        <Col xs={6}>
          <Badge bg="warning">{props.numberPlate}</Badge>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>{props.description}</Col>
      </Row>
    </Container>
  );
}
