import React from "react";
import { Container, Row, Col, Badge } from "react-bootstrap";
import styles from "./OrdersPage.module.scss";
import * as cx from "classnames";
import { useNavigate } from "react-router-dom";

export default function OrdersPage() {
  const navigate = useNavigate();

  return (
    <Container className={cx(styles.ordersPage)}>
      <h1>Your orders</h1>
      {[1,2,3,4,5].map((item) => {
        return       <div className={cx(styles.orderComp)}>
        <Row className={cx(styles.ordersPageRow)}>
          <Col xs={3} onClick={(e)=>navigate('/tracking')}>
          <Badge bg="success">delivered</Badge>
          </Col>
          <Col xs={3} onClick={(e)=>navigate('/tracking')}>
          <Badge bg="primary">N$ 2000.00</Badge>
          </Col>
          <Col xs={6} onClick={(e)=>navigate('/tracking')}>
          <Badge bg="secondary">2023-02-06 13:04</Badge>
          </Col>
          <Col xs={12} onClick={(e)=>navigate('/tracking')}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut voluptate quas odit dolorem natus quaerat.</Col>
        </Row>
      </div>
      })}
    </Container>
  );
}
