import { useEffect, useState } from "react";
import PlantCard from "../../components/FLPlantCard/FLPlantCard";
import contentful from "../../utils/contentful";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CartContext } from "../../contexts/CartContext";
import nextId from "react-id-generator";
import FLSearchInput from "../../components/FLSearchInput/FLSearchInput";
import * as cx from 'classnames';
import styles from './SeedlingsShopPage.module.scss';

export default function SeedlingsShopPage() {
  const [entry, setEntries] = useState([]);
  const [plantCards, setPlantCards] = useState([]);

  useEffect(() => {
    contentful
      .getEntry("28d85M6SW4jVfLWGADEuzC")
      .then((response) => {
        setEntries(response.fields);
        setPlantCards(response.fields.plantCards);
      })
      .catch(console.error);
  }, []);
  return (
    <Container className={cx(styles.seedlingsShopPage)}>
      <h1>{entry.title}</h1>
      <FLSearchInput array={plantCards} setState={setPlantCards}q/>
      <br />
      <Row>
      {plantCards.map((plantCard) => {
        return (
            <Col xs={12} md={4} lg={4} key={nextId()}>
              <PlantCard plantCard={plantCard} />
            </Col>
        );
    })}
    </Row>
    </Container>
  );
}
