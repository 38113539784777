import React, { useContext } from "react";
import styles from "./FLCartCard.module.scss";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faMinusCircle } from "@fortawesome/free-solid-svg-icons";

import * as cx from "classnames";
import { CartContext } from "../../contexts/CartContext";

export default function FLCartCard({ title="product name", description="something short and sweet", qty=2, price=23.99,images=[], removeItemHandler }) {
  const { cart, setCart } = useContext(CartContext);
  return (
    <Container className={cx(styles.cartCard)}>
      <Row>
        <Col xs={3}>
          <img
            src={images[0]?.fields?.file?.url || "https://m.media-amazon.com/images/I/71r7uF8JU2L.jpg"}
            alt="plant image"
          />
        </Col>
        <Col xs={9}>
          <h3>{title}</h3>
          <p>{description}</p>
          <Row>
            <Col>
            <Row>
              <Col onClick={removeItemHandler}><FontAwesomeIcon align="end" icon={faMinusCircle} /></Col>
              <Col><p>{qty}</p></Col>
              <Col onClick={(e)=>setCart([...cart, cart.find((item) => item.title === title)])}><FontAwesomeIcon align="end" icon={faPlusCircle} /></Col>
            </Row></Col>
            <Col>
              <p className={cx(styles.priceText)}>{`N$ ${price}`}</p>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
